import React, { FunctionComponent, useEffect, useState, useRef, useCallback } from "react";
import { ConveyApi, ConveySocket, IMessageEntity, IDialectEntity, ITemplateEntity } from "@utils";
import {
  ChatWrapContainer,
  ChatContainer,
  ChatText,
  ChatInputContainer,
  ChatInput,
  ChatStatus,
  ChatNote,
  ChatTextWrap,
  ChatAuthor,
  TranslateWrap,
  TranslatedText,
  ChatToggle,
  ChatMenu,
  ChatWrap,
  ChatMenuItem,
  MessageNote,
  TemplateActionList
} from "@components/chatStyles";
import { ActionsContainer, ItalicSpan, UnderlinedSpan, FlexCenterRow } from "@components/styles";
import { Attachment } from "@components/Attachment";
import Linkify from "react-linkify";
import { connect } from "react-redux";
import { IRootState } from "@utils";
import { formatPhoneNumber, getLastLocationTime } from "@lib/utils/helpers";
import {
  setConversationLocation,
  setVideoRoomCount,
  setRemoteRecipientCamSettings,
  setVideoNoShow,
  setActiveRoomData,
  setConversationEntity
} from "@actions";
import { ChatActions } from "./ChatActions";
import { VideoActions } from "./VideoActions";
import { SendMessageCTA } from "./SendMessageCTA";
import { TranscribeActions } from "./TranscribeActions";
import { TemplatePrompt } from "@components/TemplatePrompt";
import { LocationModal } from "@components/LocationModal";
// @ts-ignore
import BulletList from "@assets/icons/bulletList.svg";
import { LanguageSelector } from "@components/Video/LanguageSelector";

interface ISMSChatProps {
  conversationId: string;
  identity: string;
  recipientId: string;
  phoneNumber: string;
  ui?: boolean;
  greetingMessage?: string;
  smsLanguage?: string;
  dialects: IDialectEntity[];
  recipient?: boolean;
  locationRequest?: boolean;
  onSMS: (message: IMessageEntity) => void;
  onSMSEnded: (closed: boolean) => void;
  $element: HTMLElement;
  conversations: IRootState["conversations"];
  agency: IRootState["agency"];
  app: IRootState["app"];
  setConversationLocation: typeof setConversationLocation;
  setVideoRoomCount: typeof setVideoRoomCount;
  setRemoteRecipientCamSettings: typeof setRemoteRecipientCamSettings;
  setActiveRoomData: typeof setActiveRoomData;
  setConversationEntity: typeof setConversationEntity;
}

const XSMSChat: FunctionComponent = ({
  conversationId,
  identity,
  dialects,
  recipientId,
  phoneNumber,
  locationRequest,
  onSMS,
  onSMSEnded,
  ui,
  recipient,
  conversations,
  app,
  agency,
  setConversationLocation,
  setVideoRoomCount,
  setRemoteRecipientCamSettings,
  setActiveRoomData,
  setConversationEntity
}: ISMSChatProps) => {
  const { agencyId, agencyName } = app;
  const currentConversation = conversations[conversationId];
  const [active, setActive] = useState(true);
  const [ttsActive, setTTSActive] = useState(currentConversation?.entity?.channel !== "voice" ? false : true);
  const [templateShow, setTemplateShow] = useState(false);
  const [nextNavShown, setNextNavShown] = useState(false);
  const [showMap, setShowMap] = useState(0);
  const [text, setText] = useState("");
  const [, setError] = useState("");
  const [sending, setSending] = useState(false);
  const [locationIsFresh, setLocationIsFresh] = useState(false);
  const [templates, setTemplates] = useState<ITemplateEntity[]>([]);
  const intervalIDRef = useRef(null);
  const conversationIDRef = useRef(conversationId);
  const recipientIDRef = useRef(recipientId);
  const langRef = useRef("");
  const dialectRef = useRef("");

  useEffect(() => {
    (async () => {
      const messagesResponse = await ConveyApi.getMessages({ conversationId });
      const templatesResponse = await ConveyApi.getTemplates();
      const uniqueTemplates = templatesResponse.filter((template, templateIndex, self) => {
        return templateIndex === self.findIndex((t) => t.template_id === template.template_id);
      });
      setTemplates(uniqueTemplates);
      const filteredMessages = messagesResponse.filter((message) => message.source !== "transcribe");
      messagesRef.current = [...filteredMessages, ...messagesRef.current];
      setMessages([...messagesRef.current]);
      scrollBottom();
    })();
  }, []);
  const messagesRef = useRef([]);
  const [, setMessages] = useState<IMessageEntity[]>([]);
  useEffect(() => {
    ConveySocket.init(ConveyApi.readAccessToken())
      .listen(
        ConveySocket.UPDATE_CONVERSATION,
        (data) => {
          setConversationEntity(data.conversation_id, data);
        },
        true
      )
      .listen(
        ConveySocket.CREATE_ATTACHMENT,
        (data) => {
          if (data.conversation_id === conversationIDRef.current && data.presigned_url) {
            const msgIndex = messagesRef.current.findIndex((m) => m.message_id === data.message_id);
            if (msgIndex !== -1) {
              messagesRef.current[msgIndex] = {
                ...messagesRef.current[msgIndex],
                image_url: data.presigned_url,
                pending_image: false
              };
              setMessages([...messagesRef.current]);
              scrollBottom();
            }
          }
        },
        true
      )
      .listen(
        ConveySocket.CREATE_MESSAGE,
        (data) => {
          if (data.conversation_id === conversationIDRef.current && data.source !== "transcribe") {
            const messageData = {
              ...data
            };
            if (data.num_media > 0) {
              messageData.pending_image = true;
            }
            messagesRef.current = [...messagesRef.current, ...[messageData]];
            setMessages([...messagesRef.current]);
            scrollBottom();
          }
          onSMS && onSMS(data);
        },
        true
      )
      .listen(
        ConveySocket.UPDATE_RECIPIENT_LANGUAGE,
        (data) => {
          if (data.recipient_id === recipientIDRef.current) {
            const newLang =
              app.textDialects.find((d) => {
                return d.code === data?.language;
              })?.label || "";
            const newDialectCode = app.textDialects.find((d) => d.code === data?.language)?.code || "";
            langRef.current = newLang;
            dialectRef.current = newDialectCode;
            setLang(newLang);
            setDialectCode(newDialectCode);
          }
        },
        true
      )
      .listen(
        ConveySocket.UPDATE_MESSAGE_STATUS,
        (data) => {
          if (data.conversation_id === conversationIDRef.current) {
            const $statusElement = document.getElementById(`chat-status-id-${data.message_id}`);
            if ($statusElement) {
              $statusElement.innerHTML = data.status;
            }
          }
          onSMS && onSMS(data);
        },
        true
      )
      .listen(
        ConveySocket.ADD_RECIPIENT_GEOLOCATION,
        (data) => {
          if (data.recipient_id === recipientIDRef.current) {
            onSMS && onSMS(data);
            const {
              lng,
              lat,
              normalized_address,
              updated_at,
              latlng_accuracy,
              altitude,
              altitude_accuracy,
              hat,
              hat_accuracy
            } = data;
            setConversationLocation(conversationIDRef.current, {
              lng,
              lat,
              hat,
              altitude,
              normalizedAddress: normalized_address,
              updatedAt: updated_at,
              latlngAccuracy: latlng_accuracy,
              altitudeAccuracy: altitude_accuracy,
              hatAccuracy: hat_accuracy
            });
          }
        },
        true
      )
      .listen(
        ConveySocket.RECEIVE_SHARE_INFORMATION,
        (data) => {
          if (data.type === "share-video-settings") {
            setRemoteRecipientCamSettings({
              identity: data.data.identity,
              settings: data.data.mVideoSettings
            });
          }
        },
        true
      )
      .listen(
        ConveySocket.CREATE_VIDEO_ROOM,
        (data) => {
          getRoom();
        },
        true
      )
      .listen(
        ConveySocket.CLOSE_VIDEO_ROOM,
        () => {
          getRoom();
        },
        true
      );
  }, []);

  useEffect(() => {
    if (currentConversation?.location?.updatedAt) {
      setLocationIsFresh(
        currentConversation.location?.updatedAt &&
          currentConversation.location?.updatedAt / 1000 > Math.floor(Date.now() / 1000 - 15 * 60)
      );
      if (!intervalIDRef.current) {
        intervalIDRef.current = setInterval(() => {
          setLocationIsFresh(
            currentConversation.location?.updatedAt &&
              currentConversation.location?.updatedAt / 1000 > Math.floor(Date.now() / 1000 - 15 * 60)
          );
        }, 30 * 1000);
      }

      return () => {
        if (intervalIDRef.current) {
          clearInterval(intervalIDRef.current);
        }
      };
    }
  }, [currentConversation?.location?.updatedAt]);

  const createMessage = useCallback(async () => {
    setSending(true);
    const response = await ConveyApi.createMessage({
      body: text,
      conversationId,
      phoneNumber,
      recipient,
      ttsActive
    });
    setText("");
    setSending(false);
    if (response && !recipient) {
      scrollBottom();
    }
  }, [ttsActive, text, conversationId, phoneNumber, recipient]);
  const scrollBottom = () => {
    setTimeout(() => {
      const wrapperEle = document.getElementById("sms-container");
      if (wrapperEle) {
        wrapperEle.scrollTo(0, 100000);
      }
    }, 250);
  };
  const dialectLabel = (code: string) => {
    let label = "";
    try {
      label = dialects.find((o) => o.code === code).label;
    } catch (error) {
      return code;
    }
    return label;
  };

  const postLocation = async (coords: GeolocationCoordinates) => {
    await ConveyApi.postRecipientGeoLocations({
      recipientId,
      lat: coords.latitude,
      lng: coords.longitude,
      latlng_accuracy: coords.accuracy,
      altitude: coords.altitude,
      altitude_accuracy: coords.altitudeAccuracy
    });
  };

  const geoSuccessHandler = (position: GeolocationPosition) => {
    postLocation(position.coords);
  };

  const geoErrorHandler = (error: GeolocationPositionError) => {
    console.log(error);
    setError("Location settings are not enabled in your browser. Please enter your address below.");
  };

  const requestLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(geoSuccessHandler, geoErrorHandler);
      navigator.geolocation.watchPosition(geoSuccessHandler, geoErrorHandler);
    } else {
      setError("Your browser does not support geolocation API. Please enter your address manually.");
    }
  };

  useEffect(() => {
    if (recipient && locationRequest) {
      requestLocation();
    }
  }, [recipient, locationRequest]);

  useEffect(() => {
    (async () => {
      const currentRooms = await ConveyApi.getVideoRooms({
        conversationId: conversationId,
        status: "active"
      });
      if (currentRooms.length > 0) {
        setActiveRoomData(currentRooms[0]);
      }
      setVideoRoomCount(currentRooms.length);
    })();
  }, [conversationId]);

  const [lang, setLang] = useState("-- Autodetect Language --");
  const [dialectCode, setDialectCode] = useState("");

  useEffect(() => {
    if (currentConversation?.entity?.recipients && currentConversation?.entity?.recipients[0]?.language) {
      const newLang =
        app.textDialects.find((d) => d.code === currentConversation?.entity?.recipients[0]?.language)?.label || "";
      const newDialectCode =
        app.textDialects.find((d) => d.code === currentConversation?.entity?.recipients[0]?.language)?.code || "";
      langRef.current = newLang;
      dialectRef.current = newDialectCode;
      setLang(newLang);
      setDialectCode(newDialectCode);
    }
  }, [currentConversation?.entity]);

  const getRoom = async () => {
    (async () => {
      const currentRooms = await ConveyApi.getVideoRooms({
        conversationId: conversationId,
        status: "active"
      });
      if (currentRooms.length > 0) {
        setActiveRoomData(currentRooms[0]);
      } else {
        setActiveRoomData(null);
        app.videoShow && setVideoNoShow();
      }
      setVideoRoomCount(currentRooms.length);
    })();
  };

  const canSendMessage =
    agency?.settings?.enableConversationAssignment === false ||
    recipient ||
    currentConversation.entity.operator_id === null ||
    currentConversation.entity.operator_id === app.operatorId;

  const putRecipientLanguage = async (language: string) => {
    await ConveyApi.putRecipientLanguage({
      language,
      recipientId
    });
    ConveySocket.getInstance().socketEmit(ConveySocket.SHARE_INFORMATION_CMD, {
      conversationId,
      type: "recognition-language",
      data: { [recipientId]: language }
    });
  };

  return (
    active &&
    ui !== false && (
      <ChatWrap>
        <ChatWrapContainer>
          {!recipient && (
            <ActionsContainer>
              <FlexCenterRow justify="space-between" style={{ width: "100%" }}>
                {currentConversation?.entity?.primary_recipient && (
                  <div>
                    <>
                      <span data-type="Conversation-Label">
                        {formatPhoneNumber(currentConversation?.entity?.primary_recipient)}
                      </span>
                      {dialectRef.current && (
                        <LanguageSelector
                          onSelect={(selectedLanguage) => {
                            setLang(selectedLanguage.label);
                            putRecipientLanguage(selectedLanguage.code);
                          }}
                          dialects={app.textDialects}
                          currentDialect={dialectRef.current}
                          asText={true}
                        />
                      )}
                    </>
                  </div>
                )}
                <ChatActions
                  conversationId={conversationId}
                  agencyId={agencyId}
                  phoneNumber={phoneNumber}
                  recipientId={recipientId}
                  onSMSClose={() => {
                    setActive(false);
                    onSMSEnded && onSMSEnded(true);
                  }}
                />
              </FlexCenterRow>
              {locationIsFresh && currentConversation?.location && (
                <div style={{ width: "100%" }}>
                  <MessageNote
                    data-type="Conversation-Location"
                    style={{ paddingLeft: 0 }}
                    onClick={() => {
                      setShowMap(new Date().getTime());
                    }}
                  >
                    {<UnderlinedSpan>{currentConversation.location.normalizedAddress}</UnderlinedSpan>}
                    {currentConversation.location.updatedAt && (
                      <ItalicSpan>&nbsp;{getLastLocationTime(currentConversation.location.updatedAt)}</ItalicSpan>
                    )}
                  </MessageNote>
                  {!!showMap && (
                    <LocationModal
                      $mapId={"LocationHeader"}
                      show={showMap}
                      nextNavShown={nextNavShown}
                      conversationId={conversationId}
                      onClose={() => {
                        setShowMap(0);
                      }}
                      mapLoaded={() => {
                        setNextNavShown(true);
                      }}
                      locationIsFresh={locationIsFresh}
                    />
                  )}
                </div>
              )}
            </ActionsContainer>
          )}
          {!recipient && (
            <>
              <VideoActions
                conversationId={conversationId}
                agencyId={agencyId}
                phoneNumber={phoneNumber}
                recipientId={recipientId}
              />
              <TranscribeActions conversationId={conversationId} agencyId={agencyId} phoneNumber={phoneNumber} />
            </>
          )}
          <ChatContainer id="sms-container">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start"
              }}
            >
              {messagesRef.current.map((message, messageIndex) => {
                const realDirection = recipient
                  ? message.direction === "inbound"
                    ? "outbound"
                    : "inbound"
                  : message.direction;
                const realIdentity = recipient
                  ? realDirection === "outbound"
                    ? identity
                    : agencyName
                  : realDirection === "inbound"
                  ? message.recipient_label
                  : identity;
                const isNote = message.source === "note";
                const isTTS = message.source === "voice";
                const messageDirection = isNote ? "note" : realDirection;
                // const locationRequestMsg = message.body?.includes("share location");
                const bodyText =
                  realDirection === "outbound"
                    ? message.body
                    : message.body_transl
                    ? message.body_transl
                    : message.body;
                const transBodyText =
                  realDirection === "outbound"
                    ? message.body_transl
                      ? message.body_transl
                      : message.body
                    : message.body;
                return message.pending_image ? null : (
                  <ChatTextWrap
                    key={[message.message_id, messageIndex].join()}
                    direction={messageDirection}
                    hide={isNote && recipient}
                  >
                    {isNote ? (
                      !recipient && <ChatNote>{message.body}</ChatNote>
                    ) : (
                      <>
                        <ChatAuthor direction={realDirection}>{formatPhoneNumber(realIdentity)}</ChatAuthor>
                        <ChatText direction={realDirection}>
                          <Linkify>{bodyText}</Linkify>
                          {/* {realDirection === "outbound" ? bodyText : <Linkify>{bodyText}</Linkify>} */}
                          {message.body &&
                            message.body_transl &&
                            message.body !== message.body_transl &&
                            message.language &&
                            message.language.substring(0, 2) != "en" && (
                              <TranslateWrap>
                                {
                                  <>
                                    {message.language && (
                                      <ChatNote emphasized={true}>
                                        Translated {realDirection === "outbound" ? "into" : "from"}{" "}
                                        {dialectLabel(message.language)}
                                      </ChatNote>
                                    )}
                                    <TranslatedText>
                                      <Linkify>{transBodyText}</Linkify>
                                    </TranslatedText>
                                  </>
                                }
                              </TranslateWrap>
                            )}
                          <Attachment
                            messageId={message.message_id}
                            conversationId={conversationId}
                            numMedia={message.num_media}
                            imageUrl={message.image_url}
                          />
                        </ChatText>
                        {/* {locationRequestMsg && (
                          <LocationModule
                            locationIsFresh={locationIsFresh}
                            conversationId={conversationId}
                            direction={realDirection}
                            nextNavShown={nextNavShown}
                            mapLoaded={() => {
                              setNextNavShown(true);
                            }}
                            messageId={[message.message_id, messageIndex].join("")}
                          />
                        )} */}
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {isTTS && (
                            <ChatStatus id={`chat-tts-status-id-${message.message_id}`} direction={realDirection}>
                              Sent as speech
                            </ChatStatus>
                          )}
                          {currentConversation?.entity?.channel !== "voice" && (
                            <ChatStatus id={`chat-status-id-${message.message_id}`} direction={realDirection}>
                              {message.status === "pending" ? "queued" : message.status}
                            </ChatStatus>
                          )}
                        </div>
                      </>
                    )}
                  </ChatTextWrap>
                );
              })}
            </div>
          </ChatContainer>
          {
            <ChatInputContainer>
              <ChatInput
                recipient={recipient || !canSendMessage}
                onChange={(e) => {
                  setText(e.target.value);
                }}
                onKeyUp={(e) => {
                  if (e.key === "Enter" || e.keyCode === 13) {
                    createMessage();
                  }
                }}
                value={text}
                placeholder={
                  !recipient && !canSendMessage
                    ? "You must be assigned as agent on the conversation in order to reply"
                    : ""
                }
                disabled={sending || (!recipient && !canSendMessage)}
              />
              {!recipient && canSendMessage && (
                <ChatMenu>
                  <ChatToggle
                    onClick={() => {
                      setTemplateShow(!templateShow);
                    }}
                  >
                    <BulletList />
                  </ChatToggle>
                  <TemplateActionList show={templateShow}>
                    {templates.map((template, index) => (
                      <ChatMenuItem key={index}>
                        <TemplatePrompt
                          template={template}
                          conversationId={conversationId}
                          phoneNumber={phoneNumber}
                          recipient={recipient}
                        />
                      </ChatMenuItem>
                    ))}
                  </TemplateActionList>
                </ChatMenu>
              )}
              <SendMessageCTA
                ttsActive={ttsActive}
                isLoading={sending}
                isTTSEnabled={currentConversation?.entity?.channel === "voice"}
                disabled={sending || (!recipient && !canSendMessage)}
                onSendMessage={() => createMessage()}
                onChangeTTSActive={(value) => {
                  setTTSActive(value);
                }}
              />
            </ChatInputContainer>
          }
        </ChatWrapContainer>
      </ChatWrap>
    )
  );
};

const mapStateToProps = ({ conversations, app, agency }: IRootState) => ({
  conversations,
  app,
  agency
});

export const SMSChat = connect(mapStateToProps, {
  setConversationLocation,
  setVideoRoomCount,
  setRemoteRecipientCamSettings,
  setActiveRoomData,
  setConversationEntity
})(XSMSChat);
