import { createRoot, RootType } from "react-dom/client";
import React from "react";
import { HumanInterpreterCall, Provider } from "@components";
import { appendAsChild, loadBoostlingoScript } from "@lib/utils/helpers";
import { TConnectCallType } from "@lib/utils";

export interface IConveyConnectOpts {
  $container: string;
  callType: TConnectCallType;
  root?: RootType;
}

interface IConveyConveyProps {
  $element: HTMLElement;
  callType: TConnectCallType;
  root?: RootType;
}

export class ConveyConnect implements IConveyConveyProps {
  $element: HTMLElement;
  callType: TConnectCallType;
  root?: RootType;
  mounted: boolean;

  constructor(params: IConveyConnectOpts) {
    const $element = document.getElementById(params.$container);
    this.mounted = false;

    if ($element) {
      const { $newElement } = appendAsChild(params.callType, $element, params.$container, "connect");

      this.$element = $newElement;
      this.root = params.root;
      this.callType = params.callType;

      loadBoostlingoScript(() => {
        this.initConnect();
      });
    }
  }

  initConnect() {
    if (!this.root || !this.mounted) {
      this.root = createRoot(this.$element!);
      this.mounted = true;
    }

    this.root.render(
      <Provider>
        <HumanInterpreterCall showModal={true} asModal={false} defaultCallType="audio" />
      </Provider>
    );
  }

  destroy() {
    if (this.root) {
      this.root.unmount();
      this.mounted = false;
      this.root = null;
      this.$element = null;
      this.callType = null;
    }
  }

  getRoot() {
    return this.root;
  }
}
