import React, { FunctionComponent, useState, useEffect } from "react";
import { connect } from "react-redux";
import { IRootState } from "@utils";
import { GoogleMap, useJsApiLoader, MarkerF } from "@react-google-maps/api";
import { Modal } from "@components/Modal";
import {
  ChatModuleSubtitle,
  ChatModuleTitle,
  MapWrap,
  PinnacleIcon,
  Map3DContainer,
  MapIcon
} from "@components/chatStyles";
import { Altimeter } from "@components/Altimeter";
import moment from "moment-timezone";
// @ts-ignore
import Map from "@assets/icons/map.svg";
import { useWindowSize } from "@lib/utils/helpers";

interface ILocationModalProps {
  conversationId: string;
  nextNavShown: boolean;
  conversations: IRootState["conversations"];
  app: IRootState["app"];
  mapLoaded: () => void;
  locationIsFresh: boolean;
  $mapId: string;
  show: number;
  onClose?: () => void;
}

const containerStyle = {
  width: "500px",
  height: "500px"
};

declare global {
  interface Window {
    PinnacleMap: any;
  }
}

interface ILatLng {
  lat: number;
  lng: number;
}

const XLocationModal: FunctionComponent<ILocationModalProps> = ({
  conversationId,
  conversations,
  $mapId,
  app,
  nextNavShown,
  mapLoaded,
  show,
  locationIsFresh,
  onClose
}: ILocationModalProps) => {
  const { width, height } = useWindowSize();
  const [containerStyle, setContainerStyle] = useState({
    width: `${width > 600 ? "500" : width * 0.6}px`,
    height: `${height > 600 ? "500" : height * 0.6}px`
  });
  const [pinaccleLoaded, setPinnacleLoaded] = useState(false);
  const [showPinnacle, setShowPinnacle] = useState(false);
  const [active, setActive] = useState(false);
  const [center, setCenter] = useState<ILatLng | null>(null);

  useEffect(() => {
    setContainerStyle({
      width: `${width > 600 ? "500" : width * 0.6}px`,
      height: `${height > 600 ? "500" : height * 0.6}px`
    });
  }, [width, height]);

  useEffect(() => {
    setActive(!!show);
  }, [show]);

  let validData = false;
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.GOOGLE_API_KEY
  });

  const getLastLocationTime = () => {
    try {
      const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const formattedTime = moment.utc(updatedAt, "x").tz(tz).fromNow();
      return formattedTime;
    } catch (e) {
      return updatedAt;
    }
  };

  const checkPinnacleAccess = async () => {
    const response = await fetch(process.env.NEXTNAV_AUTH_API, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.NEXTNAV_APIKEY,
        "nn-headers": JSON.stringify({ "nn-timestamp": Date.now(), "nn-app-name": "3dsdkapp" })
      },
      body: JSON.stringify({
        partnerId: process.env.NEXTNAV_PARTNER_ID,
        uuid: currentConversation.entity.primary_recipient,
        sessionId: app.sessionId,
        customerId: process.env.NEXTNAV_PARTNER_ID
      })
    }).catch(() => {
      return null;
    });
    const json = await response.json();
    return json;
  };

  const loadPinnacleMap = async () => {
    const response = await checkPinnacleAccess();

    if (response) {
      window.PinnacleMap.getInstance({
        apiKey: process.env.NEXTNAV_APIKEY,
        UUID: currentConversation.entity.primary_recipient,
        partnerID: process.env.NEXTNAV_PARTNER_ID,
        customerID: process.env.NEXTNAV_PARTNER_ID
      });
      setTimeout(() => {
        window.PinnacleMap.getInstance().setMapLocation({ lat, lng });
        new window.PinnacleMap.Map($mapId, {});
        if (nextNavShown) {
          window.PinnacleMap.getInstance().updateUserLocation({
            id: currentConversation.entity.primary_recipient,
            lat,
            lng,
            hat,
            efl: null
          });
        } else {
          const { UserData, UserType } = window.PinnacleMap;
          window.PinnacleMap.getInstance().addUser(
            new UserData({
              id: currentConversation.entity.primary_recipient,
              userType: UserType.E911USER,
              lat,
              lng,
              hat,
              efl: null,
              firstName: currentConversation.entity.primary_recipient,
              lastName: currentConversation.entity.primary_recipient,
              imgPath: "https://qa.convey911.com/images/e911caller.png"
            })
          );
          mapLoaded && mapLoaded();
        }
      }, 500);
    }
  };

  useEffect(() => {
    if (pinaccleLoaded && showPinnacle) {
      loadPinnacleMap();
    }
  }, [pinaccleLoaded, showPinnacle]);

  useEffect(() => {
    if (window && document) {
      if (!document.getElementById("pinnacleSDK")) {
        const script = document.createElement("script");
        const body = document.getElementsByTagName("body")[0];
        script.src = "https://qa.convey911.com/js/pinnaclesdk/index.js";
        script.id = "pinnacleSDK";
        body.appendChild(script);
        script.addEventListener("load", () => {
          setPinnacleLoaded(true);
        });
      } else {
        setPinnacleLoaded(true);
      }
    }
  }, []);
  const currentConversation = conversations[conversationId];

  let lat = 0.0,
    lng = 0.0,
    hat = 0.0,
    updatedAt = 0.0;

  let normalizedAddress = "";

  if (currentConversation?.location) {
    const {
      lat: latC,
      lng: lngC,
      hat: hatC,
      updatedAt: updatedAtC,
      normalizedAddress: normalizedAddressC
    } = currentConversation.location;
    lat = latC || 0.0;
    lng = lngC || 0.0;
    hat = hatC || 0.0;
    updatedAt = updatedAtC || 0.0;
    normalizedAddress = normalizedAddressC || "";
    validData = true;
  }

  useEffect(() => {
    if (lat && lng) {
      setCenter({ lat, lng });
    }
  }, [lat, lng]);

  return (
    <>
      {center && validData && locationIsFresh && lat && lng && (
        <Modal
          show={active}
          title="Recipient Location"
          onClose={() => {
            onClose && onClose();
            setActive(false);
          }}
          fullScreen={showPinnacle}
          modalBody={
            isLoaded && (
              <MapWrap>
                {showPinnacle ? (
                  <>
                    <MapIcon
                      onClick={() => {
                        setShowPinnacle(false);
                      }}
                    >
                      <Map />
                    </MapIcon>
                    <Map3DContainer id={$mapId} />
                  </>
                ) : (
                  <>
                    {!!lat && !!lng && !!hat && (
                      <>
                        <Altimeter
                          padUp={15}
                          padDown={10}
                          height={250}
                          altitude={Math.ceil(hat * 3.28084)}
                          padding={4}
                        />
                        <PinnacleIcon
                          onClick={() => {
                            setShowPinnacle(true);
                          }}
                        >
                          <img src="https://qa.convey911.com/images/pmap.png" />
                        </PinnacleIcon>
                      </>
                    )}
                    {center && (
                      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={19}>
                        <MarkerF position={center} />
                      </GoogleMap>
                    )}
                    <ChatModuleTitle>{normalizedAddress}</ChatModuleTitle>
                    <ChatModuleSubtitle>Updated {getLastLocationTime()}</ChatModuleSubtitle>
                  </>
                )}
              </MapWrap>
            )
          }
        />
      )}
    </>
  );
};

const mapStateToProps = ({ conversations, app }: IRootState) => ({
  conversations,
  app
});

export const LocationModal = connect(mapStateToProps, {})(XLocationModal);
