import { TContainerExperience } from "./reducerTypes";
import { useState, useEffect } from "react";
import storage from "local-storage-fallback";
import moment from "moment-timezone";

type WindowResize = {
  width: number;
  height: number;
  isPortrait: boolean;
};

export const loadBoostlingoScript = (callback) => {
  const script = document.createElement("script");
  script.src = "https://s3.us-east-2.amazonaws.com/cdn.convey911.com/connect/connect.js";
  script.type = "text/javascript";
  script.async = true;
  script.crossOrigin = "anonymous";

  script.onload = () => {
    callback();
  };

  script.onerror = (e) => {
    console.error("Failed to load HumanInterpreter script.", { e });
  };

  document.head.appendChild(script);
};

export const useWindowSize = () => {
  const isPxlDense = window.devicePixelRatio > 1;
  const isTouch = "ontouchstart" in document.documentElement;
  const isPortrait = window.innerHeight > window.innerWidth;
  const [windowSize, setWindowSize] = useState<WindowResize>({
    width: window.innerWidth,
    height: window.innerHeight,
    isPortrait: false
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        isPortrait: isPortrait && isPxlDense && isTouch
      });
    }
    function handleOrientation(m: MediaQueryListEvent) {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        isPortrait: m.matches && isPxlDense && isTouch
      });
    }

    window.addEventListener("resize", handleResize);
    window.matchMedia("(orientation: portrait)").addListener(handleOrientation);

    handleResize();

    return () => {
      window.matchMedia("(orientation: portrait)").removeListener(handleOrientation);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        isPortrait: isPortrait && isPxlDense && isTouch
      });
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
};

export const store = () => {
  return storage;
};

export const getLastLocationTime = (updatedAt) => {
  try {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const formattedTime = moment.utc(updatedAt, "x").tz(tz).fromNow();
    return formattedTime;
  } catch (e) {
    return updatedAt;
  }
};

export const formatPhoneNumber = (str) => {
  const cleaned = ("" + str).replace(/\D/g, "");

  const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return `(${match[2]}) ${match[3]}-${match[4]}`;
  }

  return str;
};

export const writeExpire = (key, value, expiry) => {
  const item = {
    value: JSON.stringify(value),
    expiry
  };
  store().setItem(key, JSON.stringify(item));
};

export const readExpire = (key: string) => {
  const itemStr = store().getItem(key);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  if (now.getTime() > item.expiry) {
    store().removeItem(key);
    return null;
  }
  return JSON.parse(item.value);
};

export const formatSentence = (text: string): string => {
  const re = /([!?.]\s+)([a-z])/g;
  return text.replace(re, (m, $1, $2) => $1 + $2.toUpperCase());
};

export const extractStyles = ($element) => {
  const dummy = document.createElement($element.nodeName);
  document.body.appendChild(dummy);

  let s = "";
  // @ts-ignore
  window.ConveyDefaultStyles ||= getComputedStyle(dummy);
  // @ts-ignore
  const defaultStyles = window.ConveyDefaultStyles;
  const elementStyles = getComputedStyle($element);

  for (const key in elementStyles) {
    // @ts-ignore
    const defaultKey = key.replace(/-([a-z])/g, function (g) {
      return g[1].toUpperCase();
    });
    if (elementStyles[key] && defaultStyles[defaultKey] !== elementStyles[key]) {
      const styleKey = key.replace(/[A-Z]/g, (match, offset) => (offset > 0 ? "-" : "") + match.toLowerCase());
      if (elementStyles.getPropertyValue(styleKey)) {
        s += styleKey + ":" + elementStyles.getPropertyValue(styleKey) + ";";
      }
    }
  }

  return s;
};

export const delay = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const appendAsChild = (id, $element, container, experience: TContainerExperience) => {
  const fullId = `${container}-${(id || "").replace(/\D/g, "")}-${experience}`;
  const $existingElement = document.getElementById(fullId);
  const styles = extractStyles(document.getElementById(container));

  if ($existingElement) {
    $existingElement.style.cssText = styles;

    return {
      fullId,
      $newElement: $existingElement
    };
  } else {
    const $newElement = document.createElement("div");
    $newElement.style.cssText = styles;
    $newElement.setAttribute("id", fullId);
    document.getElementById(container).appendChild($newElement);

    return {
      fullId,
      $newElement
    };
  }
};
